import { clearFormErrors, passwordReveal } from "Utils/Form"

document.addEventListener("DOMContentLoaded", () => {
  const $form = $("#login-form")

  passwordReveal()

  $form.validate({
    rules: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 8,
      },
    },
  })
  $form.on("submit", function (e) {
    clearFormErrors(e.target)

    if (!$(this).valid()) {
      e.preventDefault()
    }
  })
})
